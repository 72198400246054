import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-notification-prestation-non-terminer',
  templateUrl: './notification-prestation-non-terminer.component.html',
  styleUrls: ['./notification-prestation-non-terminer.component.scss'],
})

export class NotificationPrestationNonTerminerComponent implements OnInit {
  nom: string;
  gestionnaire: string;
  constructor(private navParams: NavParams, private modalController: ModalController) {
   this.gestionnaire  = navParams.get('gestionnaire');
  }

  ngOnInit() {}
  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}
