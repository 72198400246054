import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-notification-demande-intervention',
  templateUrl: './notification-demande-intervention.component.html',
  styleUrls: ['./notification-demande-intervention.component.scss'],
})
export class NotificationDemandeInterventionComponent implements OnInit {
  details
  constructor(private navParams: NavParams, private modalController: ModalController) {
    this.details = this.navParams.get('details');
  }

  ngOnInit() { }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
    });
  }

  accept() {
    this.modalController.dismiss({
      'accept': true
    });
  }
}
