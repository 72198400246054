import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { IsAuthenticatedService } from './data/is-authenticated.service';


const routes: Routes = [
  {
    path: '', redirectTo: 'home', pathMatch: 'full', resolve: { IsAuthenticatedService }
  },
  {
    path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    resolve: { IsAuthenticatedService }, runGuardsAndResolvers: 'always'
  },

  {
    path: 'inscription',
    loadChildren: () => import('./inscription/inscription.module').then(m => m.InscriptionPageModule)
  },
  {
    path: 'compte-gestionnaire',
    loadChildren: () => import('./compte-gestionnaire/compte-gestionnaire.module')
      .then(m => m.CompteGestionnairePageModule),

  },
  {
    path: 'suppression-profil',
    loadChildren: () => import('./suppression-profil/suppression-profil.module')
      .then(m => m.SuppressionProfilPageModule)
  },
  {
    path: 'compte-artisan',
    loadChildren: () => import('./compte-artisan/compte-artisan.module').then(m => m.CompteArtisanPageModule),
  },
  {
    path: 'reset-password/:token',
    loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: 'reset',
    loadChildren: () => import('./reset/reset.module').then(m => m.ResetPageModule)
  },
  {
    path: 'choix-utilisateur-google',
    loadChildren: () => import('./home/choix-utilisateur-google/choix-utilisateur-google.module')
      .then( m => m.ChoixUtilisateurGooglePageModule)
  },
  {
    path: 'confirmation',
    loadChildren: () => import('./confirmation/confirmation.module').then( m => m.ConfirmationPageModule)
  },
  {
    path: 'check-preauth',
    loadChildren: () => import('./check-preauth/check-preauth.module').then( m => m.CheckPreauthPageModule)
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./onboarding/onboarding/onboarding.module').then( m => m.OnboardingPageModule)
  },
  {
    path: 'facturation-tiers',
    loadChildren: () => import('./facturation-tiers/facturation-tiers.module').then( m => m.FacturationTiersPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
