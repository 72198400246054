import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import {BehaviorSubject, from, Observable, of} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { GoogleAuthService } from 'ng-gapi/lib/src';

const TOKEN_KEY = 'access_token' + (environment.production ? '' : '_dev');
const REFRESH_TOKEN_KEY = 'refresh_token' + (environment.production ? '' : '_dev');
const ROLE = 'role_user';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user = null;
  token = new BehaviorSubject(null);

  constructor(private googleAuth: GoogleAuthService,
              private router: Router,
              private http: HttpClient,
              private helper: JwtHelperService,
              private storage: Storage,
  ) {
    if (!environment.production) {}
  }

  async isAuthenticated() {
    const token  = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return this.storage.get(ROLE).then(role => {
        if (role === 'ROLE_GESTIONNAIRE') {
          return this.router.navigateByUrl('/compte-gestionnaire');
        } else if (role === 'ROLE_ARTISAN') {
          return this.router.navigateByUrl('/compte-artisan');
        }
      });
    }
  }

  getRefreshtoken(token: string): Observable<any> {
    return this.http.post(environment.api_url + '/api/token/refresh', { refresh_token: token });
  }

  async getLocalRefreshToken() {
    return await this.storage.get(REFRESH_TOKEN_KEY);
  }

  getNewAccessToken() {
    const refreshToken = from(this.storage.get(REFRESH_TOKEN_KEY));
    return refreshToken.pipe(
        switchMap(token => {
          if (token) {
            return this.http.post(environment.api_url + '/api/token/refresh', { refresh_token: token });
          } else {
            // No stored refresh token
            return of(null);
          }
        })
    );
  }

  logIn(username: string, password: string) {
    return this.http.post(environment.api_url + '/api/login_check', { username, password }).pipe(
      tap((res) => {
        if (res) {
          localStorage.setItem(TOKEN_KEY, res['token']);
          localStorage.setItem(REFRESH_TOKEN_KEY, res['refresh_token']);
          const user = this.helper.decodeToken(res['token']);
          this.storage.set(ROLE, user.roles[0]);
          const redirectUrl = user.roles[0] === 'ROLE_GESTIONNAIRE' ? '/compte-gestionnaire' : '/compte-artisan';
          if (localStorage.getItem('tutorial_shown_' + user.username)) {
            this.router.navigateByUrl(redirectUrl);
          } else {
            localStorage.setItem('tutorial_shown_' + user.username, '1');
            localStorage.setItem('mode_tuto', redirectUrl.replace('/compte-', ''));
            this.router.navigateByUrl('onboarding');
          }
        }
      })
    );
  }

  async logout() {
    await this.clearStorage();
   /* return await this.storage.remove(TOKEN_KEY).then(() => {
      return this.storage.remove(REFRESH_TOKEN_KEY).then(() => this.storage.remove(ROLE)).then(() => this.router.navigateByUrl('home'))
    });*/
  }

  async clearStorage() {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    this.storage.remove(ROLE).then(() => this.router.navigateByUrl('home'));
  }

  resetPassword(email) {
    const formData = new FormData();
    formData.append('email', email);
    return this.http.post(environment.api_url + '/api/register/reset_password', formData);
  }

  async disconnect() {
    await this.clearStorage();
  }

  confirmAccount(token) {
    return this.http.get(environment.api_url + `/register/validate_email/${token}`);
  }
}
