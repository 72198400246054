import {Inject, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {MenuPopoverComponent} from './compte-gestionnaire/menu-popover/menu-popover.component';
import {
    NotifProfilIncompletComponent
} from './compte-gestionnaire/notifications/notif-profil-incomplet/notif-profil-incomplet.component';
import {
    NotifInterventionEnCoursComponent
} from './compte-gestionnaire/notifications/notif-intervention-en-cours/notif-intervention-en-cours.component';
import {
    NotifSuppressionProfilComponent
} from './compte-gestionnaire/notifications/notif-suppression-profil/notif-suppression-profil.component';
import {
    NotifSuppressionLogementComponent
} from './compte-gestionnaire/notifications/notif-suppression-logement/notif-suppression-logement.component';
import {
    NotifAnnulationInterventionComponent
} from './compte-gestionnaire/notifications/notif-annulation-intervention/notif-annulation-intervention.component';
import {
    NotifInterventionAccepteeComponent
} from './compte-gestionnaire/notifications/notif-intervention-acceptee/notif-intervention-acceptee.component';
import {NotifDevisComponent} from './compte-gestionnaire/notifications/notif-devis/notif-devis.component';
import {
    NotifInterventionAnnuleeComponent
} from './compte-gestionnaire/notifications/notif-intervention-annulee/notif-intervention-annulee.component';
import {
    NotifInterventionEnCoursRealisationComponent
} from './compte-gestionnaire/notifications/notif-intervention-en-cours-realisation/notif-intervention-en-cours-realisation.component';
import {
    NotifInterventionTermineeComponent
} from './compte-gestionnaire/notifications/notif-intervention-terminee/notif-intervention-terminee.component';
import {
    NotifInterventionCommentaireComponent
} from './compte-gestionnaire/notifications/notif-intervention-commentaire/notif-intervention-commentaire.component';
import {MenuPopoverArtisanComponent} from './compte-artisan/menu-popover-artisan/menu-popover-artisan.component';
import {
    NotificationPrepayDemandeComponent
} from './compte-artisan/notifications/notification-prepay-demande/notification-prepay-demande.component';
import {
    NotificationProfilIncompletArtisanComponent
} from './compte-artisan/notifications/notification-profil-incomplet-artisan/notification-profil-incomplet-artisan.component';
import {
    NotificationDemandeInterventionComponent
} from './compte-artisan/notifications/notification-demande-intervention/notification-demande-intervention.component';
import {
    NotificationSuppressionDevisComponent
} from './compte-artisan/notifications/notification-suppression-devis/notification-suppression-devis.component';
import {
    NotificationReceptionDemandeComponent
} from './compte-artisan/notifications/notification-reception-demande/notification-reception-demande.component';
import {
    NotificationChangerStatutComponent
} from './compte-artisan/notifications/notification-changer-statut/notification-changer-statut.component';
import {
    NotificationInterventionModifieeComponent
} from './compte-artisan/notifications/notification-intervention-modifiee/notification-intervention-modifiee.component';
import {
    NotificationInterventionAttenteValidationComponent
} from './compte-artisan/notifications/notification-intervention-attente-validation/notification-intervention-attente-validation.component';
import {
    NotificationInterventionValideeComponent
} from './compte-artisan/notifications/notification-intervention-validee/notification-intervention-validee.component';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TokenInterceptorService} from './interceptors/token-interceptor.service';
import {JwtModule, JWT_OPTIONS} from '@auth0/angular-jwt';
import {IonicStorageModule, Storage} from '@ionic/storage';
import {Ng2IbanModule} from 'ng2-iban';
import {DOCUMENT, registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {GoogleApiModule, NG_GAPI_CONFIG, NgGapiClientConfig} from 'ng-gapi/lib/src';
import {NotifTodolistComponent} from './compte-gestionnaire/notifications/notif-todolist/notif-todolist.component';
import {
    NotificationPrestationNonTerminerComponent
} from './compte-artisan/notifications/notification-prestation-non-terminer/notification-prestation-non-terminer.component';

const gapiClientConfig: NgGapiClientConfig = {
    client_id: '503170794715-0jc7mqlphd58fl7t3khhv3g8729frh04.apps.googleusercontent.com',
    discoveryDocs: ['https://analyticsreporting.googleapis.com/$discovery/rest?version=v4'],

};


// the second parameter 'fr-FR' is optional
registerLocaleData(localeFr, 'fr');


export function jwtOptionsFactory(Storage) {
    return {
        tokenGetter: () => {
            return Storage.get('access_token');
        }
    };
}

@NgModule({
    declarations: [
        AppComponent,
        NotificationPrestationNonTerminerComponent,
        NotificationInterventionValideeComponent,
        NotificationInterventionAttenteValidationComponent,
        NotificationInterventionModifieeComponent,
        NotificationChangerStatutComponent,
        NotificationReceptionDemandeComponent,
        NotificationSuppressionDevisComponent,
        NotificationDemandeInterventionComponent,
        NotificationPrepayDemandeComponent,
        NotificationProfilIncompletArtisanComponent,
        MenuPopoverArtisanComponent,
        NotifInterventionCommentaireComponent,
        NotifInterventionEnCoursRealisationComponent,
        NotifInterventionTermineeComponent,
        NotifDevisComponent,
        NotifInterventionAnnuleeComponent,
        NotifInterventionAccepteeComponent,
        NotifAnnulationInterventionComponent,
        NotifSuppressionProfilComponent,
        NotifSuppressionLogementComponent,
        NotifInterventionEnCoursComponent,
        NotifProfilIncompletComponent,
        MenuPopoverComponent,
        NotificationPrestationNonTerminerComponent,
        NotifTodolistComponent,
    ],
    entryComponents: [
        MenuPopoverComponent,
        NotificationInterventionValideeComponent,
        NotificationInterventionAttenteValidationComponent,
        NotificationInterventionModifieeComponent,
        NotificationChangerStatutComponent,
        NotificationReceptionDemandeComponent,
        NotificationSuppressionDevisComponent,
        NotificationDemandeInterventionComponent,
        NotificationPrepayDemandeComponent,
        NotificationProfilIncompletArtisanComponent,
        MenuPopoverArtisanComponent,
        NotifInterventionCommentaireComponent,
        NotifInterventionTermineeComponent,
        NotifInterventionEnCoursRealisationComponent,
        NotifInterventionAnnuleeComponent,
        NotifDevisComponent,
        NotifInterventionAccepteeComponent,
        NotifAnnulationInterventionComponent,
        NotifSuppressionLogementComponent,
        NotifSuppressionProfilComponent,
        NotifInterventionEnCoursComponent,
        NotifProfilIncompletComponent,
        NotificationPrestationNonTerminerComponent,
        NotifTodolistComponent
    ],
    imports: [
        BrowserModule,
        GoogleApiModule.forRoot({
            provide: NG_GAPI_CONFIG,
            useValue: gapiClientConfig
        }),
        Ng2IbanModule,
        IonicStorageModule.forRoot(),
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [Storage]
            }
        }),
        HttpClientModule,
        IonicModule.forRoot({mode: 'md'}),
        AppRoutingModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true
        },
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(@Inject(DOCUMENT) private document: Document) {
        /*const bowser = Bowser.parse(window.navigator.userAgent);
        if (bowser.platform.type === 'mobile') {
          if (bowser.os.name === 'Android') {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.monagil&gl=FR';
          } else {
            window.location.href = 'https://apps.apple.com/fr/app/mon-a-g-i-l/id1527234607';
          }
        }*/
    }

}
