import {Injectable} from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpClient,
    HttpErrorResponse,
    HttpResponse
} from '@angular/common/http';
import {Observable, throwError, EMPTY} from 'rxjs';
import {AuthService} from '../common/auth.service';
import {switchMap, map, catchError, finalize} from 'rxjs/operators';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Router} from '@angular/router';
import {AlertController, LoadingController} from '@ionic/angular';
import {environment} from 'src/environments/environment';

const TOKEN_KEY = 'access_token' + (environment.production ? '' : '_dev');
const REFRESH_TOKEN_KEY = 'refresh_token' + (environment.production ? '' : '_dev');

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
    token: string;
    api: string = environment.api_url + '/api/';
    constructor(private loadingController: LoadingController,
                private auth: AuthService,
                private alertController: AlertController,
                private router: Router,
                private http: HttpClient,
                private helper: JwtHelperService) {}

    // Récupère le token pour l'introduire dans le header de la requête afin qu'il soit vérifié côté serveur
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.isNoNeedTokenList(request.url) === true) {
            return next.handle(request);
        } else {
            if (this.isNoNeedLoaderList(request.url)) {
                this.loadingController.getTop().then(hashLoading => {
                    if (!hashLoading) {
                        this.loadingController.create({
                            spinner: null,
                            duration: 2000,
                            message: '<ion-img src="assets/img/logo.png"></ion-img>',
                            cssClass: 'custom-loading'
                        }).then(loading => loading.present());
                    }
                });
            }

            const token  = localStorage.getItem(TOKEN_KEY);
            if (token) {
                const isExpired = this.helper.isTokenExpired(token);
                if (!isExpired && !this.isNoNeedTokenList(request.url)) {
                    request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
                }
            }
            return next.handle(request).pipe(
                map((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                    }
                    return event;
                }),
                catchError((err: HttpErrorResponse) => {
                    if (err.status === 400) {
                        this.handle400Error(err).then(r => r);
                        return EMPTY;
                    }
                    if (err.status === 500) {
                        this.handle400Error(err).then(r => r);
                        return EMPTY;
                    }
                    if (err.status === 403) {
                        this.presentFailedAlert(err.error?.message).then(r => r);
                        this.auth.clearStorage();
                        return EMPTY;
                    }
                    if (err.status === 401) {
                        // this.presentFailedAlert(err.error?.message).then(r => r);
                        this.auth.clearStorage();
                        return EMPTY;
                        /*const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
                        console.log(refreshToken)
                        if (refreshToken) {
                            return this.auth.getRefreshtoken(refreshToken).pipe(
                                switchMap((newToken: any) => {
                                    localStorage.setItem(TOKEN_KEY, newToken.token);
                                    localStorage.setItem(REFRESH_TOKEN_KEY, newToken.refresh_token);
                                    request = request.clone(
                                        {
                                            headers: request.headers.set('Authorization', 'Bearer ' + newToken.token)
                                        }
                                    );
                                    return next.handle(request);
                                })
                            );
                        }*/
                    }
                    return throwError(err);
                }),
                finalize(() => {
                    this.loadingController.getTop().then(hasLoading => {
                        if (hasLoading) {
                            this.loadingController.dismiss().then(() => console.log('abort presenting'));
                        }
                    });
                })
            );
        }
    }

    private isNoNeedTokenList(url: string): boolean {
        return url === `${environment.api_url}/login_check`
            || url === `${environment.api_url}/api/token/refresh`
            || url.includes('https://api-adresse.data.gouv.fr')
            || url.includes('https://homologation-webpayment.payline.com/webpayment/getToken')
            || url.includes('https://webpayment.payline.com/webpayment/getToken')
            ;
    }

    private isNoNeedLoaderList(url: string): boolean {
        return url.indexOf('get_interventions') === -1
            && url.indexOf('categorie_logement') === -1
            && url.indexOf('api-adresse.data.gouv.fr') === -1
            && url.indexOf('get_demandes_recus') === -1;
    }

    private async handle400Error(err: any) {
        if (err?.error?.errors?.children?.imageFile.errors[0]) {
            await this.presentFailedAlert(err?.error?.errors?.children?.imageFile.errors[0]);
        } else if (err?.error?.message) {
            await this.presentFailedAlert(err?.error?.message);
        } else {
            await this.presentFailedAlert(err?.message);
        }
    }

    async presentFailedAlert(msg) {
        const alert = await this.alertController.create({
            header: 'Oops!',
            message: msg,
            buttons: ['OK']
        });
        await alert.present();
    }

}
