<ion-content [fullscreen]="true">
  <ion-row>
    <ion-col>
      <p>
        N'oubliez pas de cliquer sur <span class="txt-agil">"terminer l'intervention"</span> une fois la prestation réalisée et d'<span class="txt-agil">ajouter des photos de votre prestation</span>.
        <br/>
        Cela facilitera la validation de votre intervention par le client!
      </p>
      <p>
        <i>Appeler le contact sur place de la part de <span class="txt-agil">{{gestionnaire}}</span></i>.
      </p>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <div class="button-container">
        <ion-img src="../../../../assets/img/illustration_menage_no_background.png"></ion-img>
        <ion-icon class="checkmarkcirclee" (click)="dismiss()" name="checkmark-circle"></ion-icon>
      </div>
    </ion-col>
  </ion-row>
</ion-content>